:root {
    overflow-x: hidden;
    height: 100%;
}
#homeBody{
    background: #fff;
    min-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 62.5%;
    font-family: Camphor,Open Sans,Segoe UI,sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
}
#illustrations{
    position: absolute;
    right: 0;
    top:40px;
    .splash{
        width: 504px;
        height: 281px;
        //animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
        background-size: 800px 104px;
        position: relative;
    }
}
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.slick-slider{
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 95%;
    position: relative;
    .slick-arrow{
        display: none !important;
    }
    .slick-list, .slick-track{
        -webkit-transform: translate3d(0,0,0);
        -moz-transform: translate3d(0,0,0);
        -ms-transform: translate3d(0,0,0);
        -o-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
        position: relative;
    }
    .slick-list{
        overflow: hidden;
        margin: 0;
        padding: 0;
        .slick-track{
            top: 0;
            left: 0;
            .slick-slide{
                display: none;
                float: left;
                height: 100%;
                min-height: 1px;
            }
        }
    }
    .slick-dots{
        display: block;
        position: absolute;
        bottom: 10px;
        left: 45%;
        li{
            display: inline-block;
            margin-right: 5px;
            button{
                width: 8px;
                height: 8px;
                border-radius: 50%;
                font-size: 0;
            }
        }
        .slick-active{
            button{
                background: #d4dee5;
                border: 1px solid #d4dee5;
            }
        }
    }
}
.separator{
    width: 50%;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 25px;
    hr{
        margin: 0;
        display: inline-block;
        width: 15%;
        border-top: 2px solid #d4dee5;
    }
    hr.hr1{
        margin: 0 3%;
        width: 1%;
    }
    hr.hr2{
        width: 50%;
    }
}
.slick-initialized .slick-list .slick-track .slick-slide{
    display: block;
}
blockquote, body, button, dd, dl, figure, h1, h2, h3, h4, h5, h6, ol, p, pre, ul{
    margin: 0;
    padding: 0;
}
ol, ul{
    list-style: none;
}
.container, .container-lg{
    max-width: 97%;
    margin: 0 auto;
}
.toolkit-msg{
    color: white;
    margin-top: 25px;
    font-weight: 600;
    display: inline-block;
    width: auto;
    margin-right: 20px;
}
.footer-container{
    background: #172337;
    border-top: 2px solid #f1efef;
    .col{
        display: inline-block;
        vertical-align: top;
        width: 33%;
        padding: 20px 10px 20px 0px;
        a{
            text-decoration: none;
            color: white;
            font-size: 18px;
            font-weight: 400;
            font-size: 15px;
            &:hover{
                color: #d4dee5;
                text-decoration: underline;
            }
        }
    }
    .col-right{
        float: right;
        text-align: right;
        color: white;
        font-size: 15px;
        font-weight: 400;
        padding: 20px 0px 20px 10px;
        li{
            margin-bottom: 5px;
        }
    }
    .col-center{
        color: white;
        text-align: center;
        font-size: 16px;
    }
}
.privacyPolicy,.termsConditions{
    padding-top: 120px;
    h1{
        text-align: center;
        font-size: 26px;
    }
    h2{
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    p{
        font-size: 18px;
        margin-bottom: 10px;
    }
    ol{
        list-style: decimal;
        li{
            margin-bottom: 10px;
            font-size: 16px;
            ol{
                margin-left: 12px;
                margin-top: 10px;
                list-style: lower-roman;
                li{
                    font-size: 14px;
                }
            }
        }
    }
    ul{
        li{
            margin-bottom: 10px;
            font-size: 16px;
            ol{
                margin-left: 12px;
                margin-top: 10px;
                list-style: decimal;
                li{
                    font-size: 14px;
                    ol{
                        margin-left: 12px;
                        list-style: lower-roman;
                    }
                }
            }
        }
    }
}
.services-container{
    padding-top: 180px;
    padding-bottom: 50px;
    h1{
        text-align: center;
        font-size: 22px;
        font-weight: 500;
    }
    .service-types{
        margin-top: 40px;
        margin-bottom: 60px;
        .service{
            width: 33%;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            padding: 0 10px;
            text-decoration: none;
            color: inherit;
            padding-bottom: 10px;
            .svg-icon{
                width: 50%;
                margin: 0 auto;
            }
            p{
                text-align: center;
                font-size: 14px;
                margin-top: 5px;
            }
            .dark{
                font-size: 18px !important;
            }
            &:hover{
                -ms-transform: translateY(-2px);
                transform: translateY(-2px);
                box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
            }
        }
    }
}
.promotiona-section{
    padding-top: 180px;
    padding-bottom: 50px;
    .svg-icon{
        width: 100px;
        margin: 0 auto;
    }
    h1{
        text-align: center;
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 500;
    }
    h2{
        text-align: center;
        font-size: 15px;
        font-weight: 400;
    }
    h3{
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin-top: 20px;
    }
    .promotional-features{
        margin-top: 40px;
        p{
            text-align: center;
        }
    }
    .promotional-features-table-tag{
        margin-bottom: 15px;
        margin-top: 40px;
    }
    .promotional-features-table{
        border: 1px solid #ddd;
        width: 60%;
        margin-bottom: 20px;
        border-spacing: 0;
        border-collapse: collapse;
        font-size: 15px;
        td{
            border: 1px solid #ddd;
            padding: 12px;
            line-height: 1.42857143;
            vertical-align: top;
            .svg-icon{
                width: 20px;
            }
        }
    }
    .feature-details-mobile{
        margin-top:25px;
        img{
            display: inline-block;
            vertical-align: top;
            height: 350px;
            margin-right: 10px;
            border: 1px solid #d4dee5;
        }
    }
    .feature-details{
        margin-top: 25px;
        .col-2{
            width: 50%;
            display: inline-block;
            margin: 30px 0;
            vertical-align: middle;
            p{
                font-size: 22px;
                font-weight: 600;
                margin-bottom: 15px;
            }
            .svg-icon{
                margin: 0;
                width: 15px;
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
            }
            .text{
                width: 90%;
                display: inline-block;
                font-size: 16px;
            }
            img{
                max-width: 85%;
                border: 1px solid #d4dee5;
            }
            .imgText{
                text-align: center;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}
.table-layout{
    border: 1px solid #ddd;
    width: 60%;
    margin-bottom: 20px;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 15px;
    td{
        border: 1px solid #ddd;
        padding: 12px;
        line-height: 1.42857143;
        vertical-align: top;
        .svg-icon{
            width: 20px;
        }
    }
}
.features-home{
    text-align: center;
    font-size: 28px;
    margin-bottom: 30px;
}
.promotional-features-section{
    margin-top: 15px;
    text-align: center;
    .list{
        width: 33%;
        display: inline-block;
        vertical-align: top;
        padding: 0 10px;
        .box-list{
            border-radius: 2px;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
            padding: 20px 5px;
            .main{
                width: 80%;
                margin: 0 auto;
                text-decoration: none;
                .svg-icon{
                    width: 75px;
                    margin: 0 auto;
                }
                p{
                    font-size: 15px;
                    color: #1975d2;
                    margin: 5px 0px;
                    font-weight: 600;
                }
            }
            p{
                font-size: 13px;
                padding: 0px 10px;
                color: #565454;
            }
        }
    }
}
.promotional-features-section.services-provided{
    .list{
        width: 50%;
        margin-bottom: 20px;
        .box-list{
            display: block;
            text-decoration: none;
            &:hover{
                -ms-transform: translateY(-2px);
                transform: translateY(-2px);
                box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
            }
            p{
                font-size: 15px;
                padding: 0px 15px;
            }
            .main p{
                font-size: 20px;
            }
        }
    }
}
.globalNav{
    font-family: Camphor,Open Sans,Segoe UI,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    left: 0;
    top: 40px;
    right: 0;
    z-index: 500;
    perspective: 2000px;
    position: fixed;
    z-index: 10000;
    .secHeader{
        float: right;
        p{
            font-size: 15px;
            padding-top: 22px;
            padding-left: 10px;
            font-weight: 400;
            span{
                padding-right: 10px;
            }
            .smsTo{
                padding-right: 0px;
            }
        }
    }
    .container-lg{
        padding: 0;
    }
    ul{
        padding: 0;
        margin: 0;
    }
    li{
        list-style: none;
    }
    .popup {
        position: absolute;
        left: 10px;
        top: 5px;
        right: 10px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end;
        pointer-events: none;
        perspective: 2000px;
        a {
            display: block;
        }
    }
    .popupContainer {
        background: #fff;
        box-shadow: 0 50px 100px rgba(50,50,93,.1), 0 15px 35px rgba(50,50,93,.15), 0 5px 15px rgba(0,0,0,.1);
        border-radius: 4px;
        overflow: hidden;
        position: relative;
        font-size: 17px;
        line-height: 40px;
        white-space: nowrap;
        -ms-flex-positive: 1;
        flex-grow: 1;
        transform: scale(.95);
        transform-origin: 100% 0;
        opacity: 0;
        will-change: transform,opacity;
        transition-property: transform,opacity;
        transition-duration: .25s;
    }
    .popupCloseButton {
        position: absolute;
        right: 0;
        top: 0;
        width: 51px;
        height: 51px;
        font-size: 16px;
        cursor: pointer;
        &:before,&:after{
            content: '';
            position: absolute;
            background: #6772e5;
            border-radius: 1px;
            left: 14px;
            right: 14px;
            top: 24px;
            height: 3px;
            transform: rotate(45deg);
            transition: background .1s;
        }
        &:after{
            transform: rotate(-45deg);
        }
        &:hover{
            &:before,&:after{
                background: #32325d;
            }
        }
    }
    .mobileSecondaryNav {
        border-top: 2px solid #f6f9fc;
        padding: 15px 10px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        a {
            padding: 0 20px;
            min-width: 100px;
        }
    }
    .mobileSecondaryNav>ul {
        -ms-flex-positive: 1;
        flex-grow: 1;
    }
    .mobileSignIn {
        background: #f6f9fc;
        display: block;
        padding: 12px 30px;
        font-weight: 600;
    }
    .navSection.logo{
        float: left;
        .rootLink{
            padding: 15px 15px 0px 15px;
            span{
                font-size: 34px;
            }
        }
    }
    .navSection.secondary{
        display: none;
    }
    .navSection.tertiary {
        display: none;
    }
    .navSection.mobile {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: end;
        justify-content: flex-end;
        .rootLink{
            cursor: pointer;
            width: 50px;
            height: 50px;
            position: relative;
            padding: 0 30px;
            h2{
                font-size: 0;
                left: 13px;
                top: 23px;
                color: inherit;
                position: absolute;
                width: 24px;
                height: 3px;
                border-radius: 1px;
                background: currentColor;
                &:after,&:before{
                    position: absolute;
                    width: 24px;
                    height: 3px;
                    border-radius: 1px;
                    background: currentColor;
                    content: '';
                    left: 0;
                }
                &:before {
                    top: -9px;
                }
                &:after {
                    top: 9px;
                }
            }
        }
    }
    .navSection.mobile.globalPopupActive .popupContainer {
        transform: none;
        opacity: 1;
        pointer-events: auto;
    }
    .colorize{
        color: #000000;
        transition: color .1s ease;
    }
    .rootLink{
        display: inline-block;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        cursor: pointer;
    }
    .login-svc {
        padding: 16px 7px !important;
        padding-right: 0;
        a {
            border: 1px solid #000000;
            border-radius: 2px;
            padding: 4px 12px;
        }
    }
    .contact-us {
        a {
            border: 1px solid #0D72E2;
            border-radius: 2px;
            padding: 4px 12px;
            background: #0D72E2;
            color: white;
            &:hover {
                background: #0854a8;
                color: white;
            }
        }
    }
    .activeMenu{
        font-weight: 600;
        padding-bottom: 5px;
    }
    a{
        text-decoration: none;
        -webkit-tap-highlight-color: transparent;
        color: #6772e5;
        transition: color .1s;
        &:hover{
            color: #0D72E2;
        }
    }
    .item-home{
        h1{
            line-height: 50px;
            font-size: 20px;
            margin: 0;
            color: inherit;
        }
        .brand-name {
            color: #000;
        }
    }
}
.top-header {
    position: fixed;
    background: #f9f9f9;
    height: 40px;
    padding: 12px;
    z-index: 10000;
    text-align: center;
    width: 100%;
    font-size: 0.875rem;
    border-bottom: 1px solid #ececec;
    p {
        display: inline-block;
    }
    .missed-call {
        float: right;
        margin-right: 2.5%;
    }
}
.globalContent {
    -ms-flex-positive: 1;
    flex-grow: 1;
}
main {
    position: relative;
    overflow: hidden;
    display: block;
    header {
        position: relative;
        padding-bottom: 140px;
    }
    h2[class^=common-Uppercase] {
        color: #23855D;
    }
}
#intro {
    position: relative;
    .container-lg {
        padding-top: 220px;
        max-width: 95%;
        position: relative;
        h1 {
            font-size: 50px;
            font-weight: 700;
            color: #232325;
            margin-top: 50px;
        }
        h2,h3 {
            font-size: 24px;
            font-weight: 300;
            color: #000000;
            margin-top:10px;
        }
    }
}
.link-contact {
    color: #000000;
}
.feature-button{
  background: #0D72E2;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: .025em;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin: 0 auto;
  width: 150px;
  display: block;
  box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px 1px 3px;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 30px;
  &:hover {
    background: #0854a8;
  }
}
.customer-type{
    padding-bottom: 70px;
    .container-lg{
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0;
    }
}
.card-documentation{
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    margin: 10px;
    z-index: 499;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    padding: 15px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
    transition-property: color,background-color,box-shadow,transform;
    transition-duration: .15s;
    -ms-flex-preferred-size: 1%;
    flex-basis: 1%;
    padding-left: 80px;
    color: #0D72E2;
    text-decoration: none;
    font-weight: 500;
    transition: color .1s ease;
    &:hover{
        color: #32325d !important;
        -ms-transform: translateY(-2px);
        transform: translateY(-2px);
        box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
    }
    .svg-icon{
        width: 60px;
        position: absolute;
        left: 10px;
        top: calc(50% - 30px);
    }
    h2{
        margin: 0 0 5px;
        white-space: normal;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: .025em;
        padding-left: 5px;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        color: #67768E;
        padding-left: 5px;
    }
}
.globalFooterCard {
    pointer-events: auto;
    position: relative;
    .feature-btn{
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 0 14px;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
      background: #fff;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: .025em;
      color: #0D72E2;
      text-decoration: none;
      cursor: pointer;
    }
    .free-demo-conatiner{
        margin-top: 80px;
        .common-Button {
            height: 50px;
            vertical-align: middle;
            line-height: 50px;
            text-transform: none;
            font-size: 20px;
            &:hover {
                background: #0854a8;
            }
        }
    }
}
.free-credit {
    margin-top: 16px;
    font-size: 16px;
    color: #0D72E2;
    font-weight: 600;
}
.trusted-brand {
    position: relative;
    padding-bottom: 5%;
    .container-brand {
        width: 95%;
        margin: 0 auto;
        .brand-heading {
            .line {
                text-align: center;
                h2 {
                    background-color: white;
                    display: inline-block;
                    font-size: 2rem;
                    padding: 0 2rem;
                    position: relative;
                    top: 1rem;
                }
                .logos {
                    align-items: center;
                    border-top: 2px solid #eaebee;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    min-height: 100px;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                    .logos-item {
                        padding: 0 1.7rem;
                        .logos-image {
                            display: block;
                            height: auto;
                            margin: 0 auto;
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }
                }
                .extraClientSecSlider {
                    display: none;
                }
            }
        }
    }
}

.common-Link {
    color: #6772e5;
    font-weight: 500;
    transition: color .1s ease;
    &:hover{
        color: #32325d;
    }
}
.common-Uppercase {
    font-size: 17px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .025em;
}
.common-BodyText {
    font-weight: 400;
    font-size: 15px;
    color: #333333;
    line-height: 1.4;
    //text-align: justify;
    font-style: italic;
    li{
        margin-bottom: 5px;
    }
}
.starIcon{
    font-size: 80px;
    text-align: center;
    color: #23855D;
}
.toolkitIcon{
    font-size: 70px;
    text-align: center;
    color: #23855D;
}
.api-details{
    padding-top: 180px;
    padding-bottom: 50px;
    h1{
        text-align: center;
        font-size: 35px;
        letter-spacing: .025em;
        margin-bottom: 20px;
    }
    h2{
        text-align: center;
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 40px;
    }
    h3{
        text-align: center;
        font-size: 26px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
    p{
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        width: 90%;
        margin: 0 auto;
    }
    .p1{
        margin-top: 50px;
        font-weight: 600;
    }
    .p2{
        font-size: 20px;
        margin-top: 10px;
    }
    ul{
        width: 80%;
        margin: 0 auto;
        list-style: disc;
        margin-top: 20px;
        font-size: 18px;
        li{
            margin-bottom: 5px;
            list-style: none;
            .svg-icon{
                width: 20px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }
            .text{
                display: inline-block;
                vertical-align: middle;
                margin-bottom: 4px
            }
        }
    }
    .contact{
        margin: 0 auto;
        width: 225px;
        display: block;
        margin-top: 40px;
    }
}
.common-UppercaseTitle {
    font-size: 20px;
    line-height: 31px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .025em;
}
#primary {
    overflow: hidden;
}
#complete-toolkit{
    .common-UppercaseTitle {
        text-align: center;
    }
    .common-BodyText {
        padding-top: 10px;
        padding-left: 30px;
    }
}
.bold{
    font-weight: 600 !important;
}
.semi-bold{
    font-weight: 400 !important;
}
.light{
    font-weight: 300 !important;
}
.loginIcon{
    vertical-align: middle;
    font-size: 18px;
    margin-left: 5px;
}
.video-text{
    text-align: center;
    color: #23855D;
    font-size: 22px;
}
.video{
    margin: 0 auto;
    width: 560px;
    margin-top: 30px;
}
#primary{
    .feature-button{
        display: none;
    }
}
.contact-us-page {
    background: linear-gradient(180deg, #f1f6fa, #f9f8ff);
    padding-bottom: 60px;
}
.contact-us-wrapper {
    align-items: center;
    align-self: flex-end;
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 0 auto;
    padding: 0 1.25rem;
    .content-wrapper {
        width: 50%;
        align-items: center;
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        hr {
            width: 100%;
            margin: 0;
            border: none;
        }
        .trusted-section {
            margin-top: 24px;
            .trusted-label {
                font-size: 14px;
                color: #5a6370;
                margin-bottom: 8px;
            }
            .trusted-list {
                display: flex;
                gap: 8px 16px;
                flex-wrap: wrap;
                .brand-name {
                    font-size: 16px;
                    color: #5a6370;
                    font-weight: 600;
                }
            }
        }
        img {
            transform: rotateY(180deg);
        }
    }
    .content-form {
        width: 50%;
        align-items: center;
        display: flex;
        justify-content: center;
        form {
            border: none;
            box-shadow: 0 4px 34px #8583da33;
            max-width: 520px;
            padding: 50px 30px;
            width: 100%;
            border-radius: 20px;
            background: white;
            fieldset {
                border: none;
                .form-row {
                    margin-bottom: 16px;
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    label {
                        display: inline-block;
                        font-weight: 500;
                        margin-bottom: 7px;
                        color: rgba(0, 0, 0, .85);
                        font-size: 18px;
                        width: 35%;
                    }
                    input,
                    select {
                        margin: 0 0 5px;
                        appearance: none;
                        background-clip: padding-box;
                        background-color: #fff;
                        border: 1px solid #ced4da;
                        border-radius: 0.25rem;
                        color: #212529;
                        display: block;
                        font-weight: 400;
                        line-height: 1.5;
                        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                        padding: 8px 16px;
                        width: 60%;
                        font-size: 18px;
                        &:focus {
                            outline-width: 1px;
                            outline-color: #0D72E2;
                            background-color: #f3f3f3;
                        }
                    }
            
                    input {
                        -ms-flex-item-align: center;
                        align-self: center;
            
                        &:focus {
                            border-bottom: 1px solid #0D72E2;
                        }
                    }
                }
            }
            .submit-button {
                border: 0;
                margin: 0 auto;
                text-transform: none;
                display: block;
                cursor: pointer;
                font-size: 20px;
                height: 50px;
                line-height: 50px;
                padding: 0 20px;
            }
        }
    }
}
section.contact-sales-header {
    padding-bottom: 50px;
    position: relative;
    .free-demo-conatiner{
        margin-top: 15px;
        display: inline-block;
        padding: 20px;
        background: rgba(7, 187, 230, 0.5);
        border-radius: 4px;
    }
    .headline {
        position: relative;
        padding-top: 140px;
        margin-left: auto;
        margin-right: auto;
        transition: opacity .3s ease-out;
    }
    .headline-text {
        font-size: 40px;
        line-height: 50px;
        color: #32325d;
        font-weight: 600;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .common-IntroText {
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #000000;
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
    }
}

.common-Button {
    white-space: nowrap;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
    background: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .025em;
    color: #6772e5;
    text-decoration: none;
    transition: all .15s ease;
}
.common-Button--default {
    color: #fff;
    background: #0D72E2;
    &:hover {
        background: #0854a8;
    }
}
.btn-disabled {
    background: gray;
    cursor: not-allowed !important;
    &:hover {
        background: gray !important;
    }
}
.Header{
    margin: 0 auto;
    padding-top: 60px;
    position: relative;
    overflow: hidden;
    display: block;
}
.bulk-sms-pricing{
    background: linear-gradient(180deg, #f1f6fa, #f9f8ff);
    padding-bottom: 60px;
    .Header{
        padding: 180px 2%;
        padding-bottom: 0;
    }
    h2.Header-title{
        font-size: 24px;
    }
}
.pricing-package{
    padding-bottom: 50px;
    p{
        font-size: 26px;
        text-align: center;
        margin-top: 50px;
        color: #353668;
        margin-bottom: 20px;
    }
    ul{
        list-style: disc;
        margin-top: 10px;
        font-size: 18px;
        li{
            margin-bottom: 5px;
        }
    }
}
.customer-group{
    text-align: center;
    margin-bottom: 20px;
    .tagline{
        margin-bottom: 40px;
    }
    .customer-sector{
        margin-bottom: 30px;
        .icon{
            width: 25%;
            display: inline-block;
            vertical-align: top;
            .svg-icon{
                width: 180px;
                margin: 0 auto;
            }
        }
        .text{
            display: inline-block;
            width: 75%;
            vertical-align: top;
            h2{
                font-size: 20px;
            }
            p{
                font-size: 16px;
                margin-top: 10px;
            }
        }
    }
}
.dark{
    font-weight: 600;
}
.u_floatR{
    float: right;
}
.StripeBackground {
    position: absolute;
    left: 0;
    right: 0;
    transform: skewY(-12deg);
    pointer-events: none;
    top: calc(50% + 175px);
    .stripe {
        height: 155px;
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
    }
    .stripe.pattern {
        overflow: hidden;
    }
    .s0 {
        height: 5000px;
        bottom: 10px;
        background: linear-gradient(90deg,#22b57e,#b3e895);
    }
    .s2 {
        bottom: 310px;
        right: calc(50% + 260px);
        left: auto;
        width: 30%;
        min-width: 450px;
        background: linear-gradient(90deg,#0aab88,#3ebc7f);
        opacity: .75;
    }
    .s1 {
        bottom: 465px;
        right: 80%;
        background: linear-gradient(90deg,#bfd4ff,#d2f0f9);
        opacity: .2;
    }
    .s3 {
        bottom: 155px;
        left: 75%;
        background: linear-gradient(90deg,#b6e3a8,#dbf0af);
    }
    .s4 {
        bottom: 0;
        background: linear-gradient(90deg,#d7f8b7,#98e3b5);
    }
}
.Header-container {
    position: relative;
    z-index: 10;
}
.row {
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -8px;
    margin-right: -.5rem;
    margin-right: 0;
    margin-left: -8px;
    margin-left: -.5rem;
    margin-left: 0;
}
.col-xs-11, .col-xs-12{
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 8px;
    padding-right: .5rem;
    padding-right: 0;
    padding-left: 8px;
    padding-left: .5rem;
    padding-left: 0;
}
.col-xs-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}
.common-PageTitle {
    font-weight: 400;
    font-size: 34px;
    line-height: 43px;
    color: #32325d;
}
.Header-title {
    text-align: center;
    margin: 0;
    font-size: 35px;
    line-height: 1;
    padding: 0px 0px 40px 0px;
}
.center-xs {
    -ms-flex-pack: center;
    justify-content: center;
}
.about {
    position: relative;
    background: #249bd6;
    background: -webkit-linear-gradient(left,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15)),-webkit-linear-gradient(bottom right,#49c3c9,#249bd6 50%,#0c5b9c);
    background: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,0) 50%,hsla(0,0%,100%,.15) 0,hsla(0,0%,100%,.15)),linear-gradient(to top left,#49c3c9,#249bd6 50%,#0c5b9c);
    background-blend-mode: soft-light;
    padding-top: 180px;
    padding-bottom: 50px;
    color: #b5deff;
    text-shadow: 0 1px 2px rgba(0,0,0,.2);
    .content{
        position: relative;
        max-width: 952px;
        margin: 0 auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -o-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: inherit;
        align-items: inherit;
        i{
            font-size: 35px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 5px;
        }
        span{
            display: inline-block;
            vertical-align: middle;
        }
        h2{
            color: #fff;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }
        h3{
            margin-bottom: 1em;
            font-weight: 400;
            color: #fff;
            font-size: 16px;
            line-height: 1.4em;
        }
    }
    .bio{
        position: relative;
        float: left;
        box-sizing: border-box;
        width: 50%;
        padding-right: 75px;
    }
    .who{
        display: block;
        position: relative;
        float: right;
        box-sizing: border-box;
        width: 50%;
        padding-left: 75px;
        list-style-type: none;
        margin: 0;
    }
}
.secondTitleGreen{
    color: #066FF4;
    font-size: 16px;
    margin: 15px 0;
    text-align: center;
}
.mainTitleGreen{
    color: #20835B;
    font-size: 25px;
    text-align: center;
}
.common-PageSubtitle {
    font-weight: 300;
    font-size: 32px;
    line-height: 43px;
    color: #6772e5;
}
.Header-subTitle {
    color: #fff;
}
.middle-xs {
    -ms-flex-align: center;
    align-items: center;
}
.Plan {
    box-shadow: 0px 9px 18px rgba(50, 50, 93, 0.1),
        0 5px 15px rgba(0, 0, 0, 0.07);
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.Plan--standard {
    background: #fff;
    z-index: 10;
}
.Plan-title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding: 35px 30px 20px;
    margin: 0 auto 20px;
    color: #23855D;
    border-bottom: 2px solid #f6f9fc;
}
.Plan-list {
    font-size: 17px;
    line-height: 26px;
    color: #525f7f;
    margin: 24px 0;
    max-width: 390px;
    padding: 0 30px;
    list-style: none;
}
.custom-price {
    background: #f6f9fc;
    color: #32325d;
    padding: 16px 16px 16px 30px;
    font-size: 17px;
}
.Plan-listItem {
    margin-bottom: 16px;
    position: relative;
    i{
        vertical-align: sub;
        margin-right: 5px;
        font-size: 20px;
    }
}
.Plan-button {
    background: #f6f9fc;
    display: block;
    color: #32325d;
    text-align: center;
    text-decoration: none;
    padding: 25px;
    transition: background .15s ease;
}
.col-xs-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
}
.Plan--enterprise {
    background: #32325d;
    background-image: linear-gradient(to bottom right,#32325d,#29294c);
    border-radius: 0 0 4px 4px;
    z-index: 5;
}
.Plan-title--enterprise {
    color: #cfd7df;
    font-size: 18px;
    border-bottom-color: rgba(85,90,191,.1);
}
.Plan-description {
    color: #aab7c4;
    font-size: 16px;
    line-height: 1.55em;
    text-align: center;
    margin: 0 auto;
    max-width: 86%;
    padding: 0 40px;
}
.Plan-tableContainer {
    padding: 0 40px;
}
.Plan-table {
    width: 100%;
    max-width: 100%;
    border-bottom: 0;
    border-collapse: collapse;
    margin: 24px auto 10px;
}
.Plan-table-cell {
    padding: 1.05em .5em;
    padding: 1.1em .5em;
    box-sizing: border-box;
    width: 50%;
    text-align: center;
    color: #e6ebf1;
    font-size: 17px;
    line-height: 1.4em;
    border: 1px solid #373767;
    span{
        font-size: 22px;
    }
}
.col-35{
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;
    max-width: 35%;
}
.col-65{
    -ms-flex-preferred-size: 65%;
    flex-basis: 65%;
    max-width: 65%;
}
.Plan-Button--enterprise {
    background: rgba(85,90,191,.2);
    color: #87bbfd;
}
.starService{
    width: 42%;
    text-align: center;
}
.toolkit{
    width: 50%;
}
.sms-toolkit{
    .container-lg{
        margin-top: 90px;
    }
}
.clients{
    background: #f6f6f6;
    padding-top: 50px;
    padding-bottom: 50px;
    color: #333;
    .clientHeader{
        font-weight: 600;
        display: block;
        margin: 0 auto;
        width: 400px;
        margin-bottom: 10px;
        text-align: center;
        span{
            color:#0f5ea0;
        }
    }
    .clientSpeak{
        padding: 20px 0px;
        padding-bottom: 0px;
        width: 96%;
        margin: 0 auto;
        div{
            width: 30%;
            border-top: 3px solid #1975d2;
            padding-top: 15px;
            display: inline-block;
            vertical-align: top;
            margin-right: 30px;
            &:nth-child(3n){
                margin-right: 0px;
            }
            i{
                text-align: center;
                color: #1b74d2;
                width: 100%;
            }
            h2{
                font-size: 22px;
                text-align: center;
                color: #1975d2;
                margin-bottom: 5px;
            }
            h4{
                font-size: 14px;
                text-align: center;
                font-weight: 400;
                color: #4d4d4d;
            }
            p{
                margin-top: 5px;
                font-size: 16px;
                text-align: justify;
                font-style: italic
            }
        }
    }
}
.m-price-btn,.m-feature-details{
    display: none;
}
.features-page{

}
.m-customer-type{
    display: none;
}
.dropdown-content {
    a {
        &:hover {
            background: #eeeeee;
        }
    }
    
}
.product-list {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: #77767C;
    text-align: center;
}
@media (min-width: 1366px){
    .top-header-container {
        width: 1280px;
        margin: 0 auto;
    }
    .container-lg, .container {
        width: 1280px;
    }
    .globalNav {
        .navSection.logo .rootLink {
            padding: 5px 15px 0px 0px;
        }

        .rootLink {
            padding: 25px 15px;
            padding-bottom: 10px;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);
            z-index: 1;
            top: 50px;
            left: 0;

            a {
                padding: 12px 16px;
                display: block;
                font-size: 14px;
            }
        }

        .services:hover .dropdown-content {
            display: block;
        }

        .services:hover .services {
            background-color: #3e8e41;
        }

        .navSection.secondary {
            display: inline-block;
            position: relative;
            margin-left: 16px;
        }
        .navSection.tertiary {
            display: block;
            float: right;
        }

        .navSection.mobile {
            display: none;
        }
    }
    .api-details-container {
        width: 1280px;
        margin: 0 auto;
        p, ul {
            width: 100%;
        }
    }

    .sms-toolkit .container-lg {
        margin-top: 50px;
    }

    .col-lg-6 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: .5rem;
        padding-right: 0;
        padding-left: .5rem;
        padding-left: 0;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    #intro {
        height: 320px;

        .container-lg {
            max-width: 1280px;
            .product-list {
                text-align: unset;
            }
            h1,
            h2,
            h3 {
                width: 45%;
            }

            h1 {
                font-size: 2.5rem;
                margin-top: 24px;
            }

            h2 {
                margin-top: 18px;
                font-size: 1.75rem;
            }
        }
    }

    .globalFooterCard .free-demo-conatiner {
        max-width: 1280px;
        margin: 0 auto;
        margin-top: 220px;
    }

    #complete-toolkit>* {
        -ms-flex: none;
        flex: none;
    }

    #complete-toolkit {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: row;
        margin-top: 20px;
        -ms-flex-pack: center;
        justify-content: space-between;
        height: 460px;
        align-items: center;

        .common-UppercaseTitle {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            margin: 0;
            text-align: left;
        }
    }

    section.contact-sales-header {
        .headline {
            width: 1280px;
        }

        .headline-text {
            max-width: none;
        }
    }

    .col-md-6 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: .5rem;
        padding-right: 0;
        padding-left: .5rem;
        padding-left: 0;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }

    #illustrations {
        top: 280px;
        width: 50%;

        .phone-small {
            width: 100%;

            img {
                transform: scaleY(1.45);
                float: right;
            }
        }
    }

    .bulk-sms-pricing {
        .Header {
            padding: 180px 0;
            padding-bottom: 0;
        }

        .Header-subTitle.common-PageSubtitle {
            display: block;
        }
    }

    .pricing-package {
        margin: 0 auto;
        width: 1280px;
    }

    .col-35 {
        -ms-flex-preferred-size: 35%;
        flex-basis: 35%;
        max-width: 35%;
    }

    .col-65 {
        -ms-flex-preferred-size: 65%;
        flex-basis: 65%;
        max-width: 65%;
    }
    .clients .clientSpeak {
        width: 1280px;
    }
    .Header-container {
        width: 1280px;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) and (max-width: 1365px) {
    .Plan-table-cell {
        font-size: 15px;
        span {
            font-size: 20px;
        }
    }
    .top-header {
        .missed-call {
            margin-right: 1.5%;
        }
    }
    .globalNav{
        .container-lg{
            max-width: 1280px;
        }
        .navSection.logo .rootLink{
                padding: 5px 15px 0px 0px;
        }
        .rootLink {
            padding: 25px 15px;
            padding-bottom: 10px;
        }
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.8);
            z-index: 1;
            top: 50px;
            left: 0;
            a{
                padding: 12px 16px;
                display: block;
                font-size: 14px;
            }
        }
        .services:hover .dropdown-content {display: block;}
        .services:hover .services {background-color: #3e8e41;}
        .navSection.secondary {
            display: inline-block;
            position: relative;
            margin-left: 16px;
        }
        .navSection.tertiary {
            display: block;
            float: right;
        }
        .navSection.mobile {
            display: none;
        }
    }
    .sms-toolkit .container-lg{
        margin-top: 50px;
    }
    .col-lg-6{
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: .5rem;
        padding-right: 0;
        padding-left: .5rem;
        padding-left: 0;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    #intro {
        height: 320px;
        .container-lg{
            .product-list {
                text-align: unset;
            }
            h1,h2,h3{
                width: 45%;
            }
            h1 {
                font-size: 2.4rem;
                margin-top: 24px;
            }
            h2{
                margin-top: 18px;
                font-size: 1.75rem;
            }
        }
    }
    .globalFooterCard .free-demo-conatiner{
        margin-left: 2.5%;
        margin-top: 220px;
    }
    #complete-toolkit>* {
        -ms-flex: none;
        flex: none;
    }
    #complete-toolkit {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: row;
        margin-top: 20px;
        -ms-flex-pack: center;
        justify-content: space-between;
        height: 460px;
        align-items: center;
        .common-UppercaseTitle {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            width: 100%;
            margin: 0;
            text-align: left;
        }
    }

    section.contact-sales-header{
        .headline {
            padding-top: 140px;
            max-width: 80%;
        }
        .headline-text {
            max-width: none;
        }
    }

    .col-md-6{
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: .5rem;
        padding-right: 0;
        padding-left: .5rem;
        padding-left: 0;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    #illustrations{
        top: 280px;
        width: 50%;
        .phone-small{
            width: 100%;
            img{
                transform: scaleY(1.45);
                float: right;
            }
        }
    }
    .bulk-sms-pricing{
        .Header{
            padding: 180px 2%;
            padding-bottom: 0;
        }
        .Header-subTitle.common-PageSubtitle{
            display: block;
        }
    }
    .pricing-package{
        margin: 0 auto;
        width: 90%;
    }
    .col-35{
        -ms-flex-preferred-size: 35%;
        flex-basis: 35%;
        max-width: 35%;
    }
    .col-65{
        -ms-flex-preferred-size: 65%;
        flex-basis: 65%;
        max-width: 65%;
    }
}
@media (min-width: 1025px) and (max-width: 1200px) {
    .Plan-table-cell {
        font-size: 14px;
        span {
            font-size: 17px;
        }
    }
    main header {
        padding-bottom: 170px;
    }
    .top-header {
        .missed-call {
            margin-right: 1.5%;
        }
    }
    .globalNav {
        .navSection.logo .rootLink {
            padding: 0;
            padding-top: 5px;
        }

        .rootLink {
            padding: 25px 15px;
            padding-bottom: 10px;
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.8);
            z-index: 1;
            top: 50px;
            left: 0;

            a {
                padding: 12px 16px;
                display: block;
                font-size: 14px;
            }
        }

        .services:hover .dropdown-content {
            display: block;
        }

        .services:hover .services {
            background-color: #3e8e41;
        }

        .navSection.secondary {
            display: inline-block;
            position: relative;
            margin-left: 16px;
        }
        .navSection.tertiary {
            display: block;
            float: right;
        }

        .navSection.mobile {
            display: none;
        }
        .secHeader {
            .smsTo {
                display: none;
            }
        }
    }

    .sms-toolkit .container-lg {
        margin-top: 50px;
    }

    .col-lg-6 {
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: .5rem;
        padding-right: 0;
        padding-left: .5rem;
        padding-left: 0;
        -ms-flex-preferred-size: 50%;
        flex-basis: 30%;
        max-width: 30%;
    }
    .col-65 {
        flex-basis: 70%;
        max-width: 70%;
    }

    #intro {
        height: 320px;

        .container-lg {
            .product-list {
                text-align: unset;
            }

            h1,
            h2,
            h3 {
                width: 45%;
            }

            h1 {
                font-size: 2rem;
                margin-top: 24px;
            }

            h2 {
                margin-top: 40px;
                font-size: 1.55rem;
            }
        }
    }

    .globalFooterCard .free-demo-conatiner {
        margin-left: 2.5%;
        margin-top: 220px;
    }

    #illustrations {
        top: 280px;
        width: 50%;

        .phone-small {
            width: 100%;

            img {
                transform: scaleY(1.45);
                float: right;
            }
        }
    }
    .trusted-brand {
        .container-brand {
            .brand-heading {
                .line {
                    .logos {
                        .logos-item {
                            padding: 0 0.85rem;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 769px) and (max-width: 1024px){
    .top-header {
        font-size: 0.75rem;
        .missed-call {
            margin-right: 1.5%;
        }
    }
    #intro .container-lg {
        width: 95%;
    }
    .contact-us-wrapper .content-form form {
        padding: 50px 0;
    }
    .globalNav{
        .rootLink{
            padding: 25px 4px;
            padding-bottom: 10px;
        }
        .navSection.logo .rootLink{
            padding: 5px 15px 0px 0px;
        }
        .navSection.secondary {
            display: inline-block;
        }
        .navSection.tertiary {
            display: block;
            float: right;
        }
        .navSection.mobile {
            display: none;
        }
        .dropdown-content{
            display: none;
        }
        .secHeader{
            .smsTo{
                display: none;
            }
        }
        .login-svc {
            a {
                border: 1px solid #000000;
                border-radius: 2px;
                padding: 4px;
            }
        }

        .contact-us {
            a {
                border: 1px solid #0D72E2;
                border-radius: 2px;
                padding: 4px;
                background: #0D72E2;
                color: white;
            }
        }
    }
    .trusted-brand {
        .container-brand {
            .brand-heading {
                .line {
                    .extraClientSecSlider {
                        display: inline-block;
                    }
                    .logos {
                        display: inline-block;
                        border: 0;
                    }
                }
            }
        }
    }
    #intro {
        height: 360px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        .container-lg {
            .product-list {
                text-align: unset;
            }
            h1{
                margin-top: 18px;
                width: 45%;
                font-size: 1.5rem;
            }
            h2,h3{
                width: 45%;
                margin-top: 50px;
                font-size: 1.2rem;
            }
        }
    }
    main header{
        padding-bottom: 90px;
    }
    .sms-toolkit{
        .container-lg{
            margin-top: 0;
            .toolkit-msg{
                margin-top: 0px;
                display: block;
            }
            .feature-btn{
                margin-top: 15px;
            }
        }
        .free-demo-conatiner{
            margin-left: 2.5%;
            margin-top: 120px;
        }
    }
    .video{
        width: 350px;
        iframe{
            width: 350px;
            height: 250px;
        }
    }
    .common-UppercaseTitle {
        font-size: 22px;
        line-height: 33px;
    }
    #complete-toolkit>* {
        -ms-flex: none;
        flex: none;
    }
    #complete-toolkit {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: row;
        margin-top: 50px;
        padding: 0;
        -ms-flex-pack: center;
        justify-content: space-between;
        height: 460px;
        align-items: center;
        .common-BodyText {
            width: 100%;
            text-align: left;
        }
        .common-UppercaseTitle {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-align: center;
            align-items: center;
            text-align: left;
        }
    }
    .card-details-features{
        padding: 0px;
    }
    .clients{
        .clientSpeak{
            width: 95%;
        }
    }
    .clientSec {
        height: 110px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;

        &:before,
        &:after {
            background: -webkit-gradient(linear, left top, right top, from(#fff), to(hsla(0, 0%, 100%, 0)));
            background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
            content: "";
            height: 140px;
            position: absolute;
            width: 220px;
            z-index: 2;
        }

        &:before {
            left: 0;
            top: 0;
        }

        &:after {
            right: 0;
            top: 0;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .clientSecSlider {
            -webkit-animation: scroll 40s linear infinite;
            animation: scroll 40s linear infinite;
            display: inline-block;
        }
        .extraClientSecSlider {
            display: flex;
        }

        .logos-item {
            height: 90px;
            width: 200px;
            display: inline-block;
        }
    }
    .feature-button{
        margin-top: 30px;
    }

    section.contact-sales-header{
        .headline {
            padding-top: 140px;
            max-width: 100%;
        }
        .headline-text{
            max-width: unset;
        }
    }
    .common-IntroText {
        font-size: 24px;
        line-height: 36px;
    }

    .Header-subTitle {
        display: block;
    }
    .common-PageSubtitle {
        line-height: 50px;
    }
    .col-sm-6{
        box-sizing: border-box;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding-right: .5rem;
        padding-right: 0;
        padding-left: .5rem;
        padding-left: 0;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .Plan--enterprise {
        border-radius: 0 4px 4px 0;
    }
    #illustrations{
        width: 55%;
        top: 270px;
        .phone-small {
            width: 100%;
    
            img {
                transform: scaleY(1.45);
                float: right;
            }
        }
    }
    .customer-type{
        margin-top: -50px;
    }
    .footer-container{
        .container-lg{
            padding: 0;
        }
    }
    .customer-group .customer-sector{
        .text{
            padding: 0 10px;
        }
        .icon .svg-icon{
            width: 140px;
        }
    }
    .services-container{
        padding: 180px 0 50px 0;
    }
    .promotiona-section.container{
        max-width: 95%;
        padding: 180px 0 50px 0;
        .svg-icon{
            width: 130px;
        }
    }
    .promotiona-section.features-page.container{
        h1{
            font-weight: 600;
            font-size: 20px;
        }
        h2{
            font-size: 18px;
        }
    }
    .promotiona-section .feature-details-mobile img{
        margin-bottom: 10px;
    }
    .col-35,.col-65{
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .col-65{
        margin-top: 20px;
    }
    .Plan-title{
        padding: 20px;
    }
    .Plan-description{
        padding: 0px 20px;
        max-width: 95%;
    }
    .Plan-tableContainer{
        padding: 0px 20px;
    }
    .Plan-list{
        margin: 20px auto;
        max-width: 100%;
    }
    .Plan-table-cell{
        padding: 15px;
        font-size: 15px;
        span {
            font-size: 20px;
        }
    }
    .pricing-package{
        width: 90%;
        margin: 0 auto;
        p{
            margin-top: 35px;
            font-size: 22px;
        }
        .table-layout{
            width: 100%;
        }
    }
    .about{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15)), linear-gradient(to top left, #49c3c9, #249bd6 50%, #0c5b9c);
        margin:0px;
        padding: 160px 20px;
        .content{
            display: block;
        }
        .bio{
            width: 100%;
            float: none;
            padding: 0px;
        }
        .who{
            width: 100%;
            float: none;
            padding: 0;
        }
    }
    .bulk-sms-pricing{
        .Header{
            padding: 0 15px;
            padding-top: 120px;
            .Header-title{
                font-size: 26px;
                padding: 40px 0px 30px 0px;
                .common-PageSubtitle{
                    font-size: 28px;
                }
            }
            h2.Header-title{
                padding-top: 10px;
                padding-bottom: 0;
            }
        }
    }
    .api-details{
        padding: 160px 10px 50px 10px;
        h1{
            padding: 0px 10px;
            font-size: 32px;
        }
        p{
            font-size: 18px;
            width: 95%;
            line-height: 1.3;
        }
        ul{
            width: 90%;
        }
    }
}
@media (min-width: 481px) and (max-width: 768px) {
    .top-header {
        height: 60px;
        .missed-call {
            display: none;
        }
    }
    .contact-us-wrapper {
        flex-wrap: wrap;
        .content-form, .content-wrapper {
            width: 100%;
        }
    }
    .globalNav {
        top: 60px;
        .secHeader {
            display: none;
        }

        .navSection.mobile {
            padding: 5px 0px;
        }

        .navSection.logo {
            position: absolute;
            margin: 0px;

            .rootLink {
                padding: 0px;
            }
        }
    }
    .trusted-brand {
        .container-brand {
            .brand-heading {
                .line {
                    .extraClientSecSlider {
                        display: inline-block;
                    }

                    .logos {
                        display: inline-block;
                        border: 0;
                    }
                }
            }
        }
    }

    .globalContent .container-lg {
        padding: 0;
    }

    .services-container {
        padding: 140px 0px 20px 0px;

        .service-types {
            margin-bottom: 20px;
            margin-top: 15px;

            .service {
                margin-bottom: 30px;
                width: 100%;
                border-radius: 2px;
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
                padding: 10px;

                .svg-icon {
                    width: 25%;
                }
            }
        }
    }

    .customer-group {
        .customer-sector {
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
            padding-bottom: 10px;

            .icon,
            .text {
                vertical-align: middle;
                padding: 0 5px;

                .svg-icon {
                    width: 100%;
                }
            }
        }
    }

    .promotiona-section {
        padding: 140px 0 30px 0;

        .feature-details-mobile {
            img {
                margin: 0 auto;
                width: 225px;
                display: block;
                margin-bottom: 10px;
            }
        }

        .feature-details {
            display: none;

            .col-2 {
                width: 100%;
                margin: 20px 0;

                p {
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                img {
                    margin-bottom: 10px;
                    max-width: 100%;
                }

                .svg-icon {
                    vertical-align: top;
                }

                .text {
                    vertical-align: top;
                    margin-bottom: 10px;
                }
            }
        }

        .m-feature-details {
            display: block;
        }

        .promotional-features-table {
            width: 100%;

            td {
                padding: 10px;
            }
        }
    }

    .promotional-features-section .list {
        width: 100%;
        padding: 0px;
        margin-bottom: 10px;

        .box-list {
            padding: 20px 0px;

            p {
                padding: 0px 10px;
            }

            .main {
                .svg-icon {
                    width: 50px;
                }

                p {
                    padding: 0;
                }
            }
        }
    }

    section.contact-sales-header {
        padding-top: 50px;

        .headline {
            padding-top: 100px;
            max-width: 100%;

            .container-lg {
                padding: 0;
            }

            .headline-text {
                font-size: 30px;
                margin-bottom: 0;
            }
        }

        .free-demo-conatiner {
            .common-Button--default {
                margin-top: 15px;
            }
        }
    }

    .video-text,
    .video {
        display: none;
    }

    .footer-container {
        .col {
            width: 100%;
            padding: 20px 10px 0px 0px;
        }

        .col-right {
            float: unset;
            text-align: unset;
            padding: 20px 0px 20px 0px;
        }

        .col-center {
            text-align: unset;
            padding: 20px 0px 0px 0px;
        }
    }

    .sms-toolkit {
        .container-lg {
            margin-top: 20px;
            padding: 0;
        }

        .free-demo-conatiner {
            margin-top: 50px;
            text-align: center;
            border-radius: 0;
            .common-Button--default {
                margin-top: 15px;
                width: 220px;
                text-align: center;
            }
        }

        .feature-btn {
            margin: 0 auto;
            width: 240px;
            display: block;
        }
    }

    .toolkit-msg {
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }

    #illustrations {
        display: none;
    }

    main header {
        padding-bottom: 100px;
    }

    .customer-type {
        margin-top: -60px;
        padding-bottom: 30px;

        .card-documentation {
            flex: unset;
            display: inline-block;
            flex-basis: unset;
            width: 100%;
            margin: 5px;
            padding: 10px 5px;

            .svg-icon {
                position: static;
                width: 40px;
                margin: 0 auto;
            }

            h2 {
                text-transform: unset;
                padding-left: 0px;
                text-align: center;
            }

            p {
                font-size: 12px;
                text-align: center;
            }
        }
    }

    .m-customer-type {
        display: block;
    }

    .promotional-features-section.services-provided {
        margin-top: 0;

        .list {
            width: 100%;
        }
    }

    #intro {
        .container-lg {
            padding: 140px 0px 40px 0px;

            h2,
            h3 {
                margin-top: 30px;
                font-size: 1.5rem;
                text-align: center;
            }

            h1 {
                margin-top: 10px;
                text-align: center;
                font-size: 2rem;
                font-weight: 600;
            }
        }
    }

    #complete-toolkit {
        padding: 0;

        .starService {
            width: 100%;

            .common-BodyText {
                padding-left: 0px;
            }

            .mainTitleGreen {
                font-size: 20px;
            }
        }

        .toolkit {
            width: 100%;
            margin: 30px auto;

            span {
                display: inline-block;
            }
        }

        .m-price-btn {
            display: block;
            width: auto;
            margin-top: 20px;
        }
    }

    .features-home {
        font-size: 24px;
    }

    .feature {
        width: 90%;
        margin-bottom: 15px;
        margin: 0 auto;
        display: block;

        p {
            width: 85%;
            margin-bottom: 10px;
        }
    }

    .detailed {
        height: auto;
        padding-bottom: 30px;

        .container-lg {
            padding: 0;
        }
    }

    .api-details {
        height: auto;
        padding-bottom: 30px;
        padding-top: 140px;

        h1 {
            padding: 0px 10px;
            font-size: 20px;
        }

        h2 {
            padding: 0 10px;
            font-size: 16px;
            margin-bottom: 15px;
        }

        p {
            font-size: 18px;
            width: 90%;
            line-height: 1.3;
        }

        ul {
            width: 90%;

            li {
                .text {
                    width: 88%;
                }
            }
        }
    }

    .col-35,
    .col-65 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }

    .col-65 {
        margin-top: 20px;
    }

    .Plan-title {
        padding: 20px 10px;
    }

    .Plan-description {
        padding: 0px;
        max-width: 95%;
    }

    .Plan-tableContainer {
        padding: 0px 10px;
    }

    .Plan-list {
        margin: 20px auto;
        padding: 0px 20px;
    }

    .Plan-table-cell {
        padding: 10px;
        font-size: 13px;
    }

    .pricing-package {
        width: 90%;
        margin: 0 auto;

        p {
            margin-top: 35px;
            font-size: 22px;
        }

        .table-layout {
            width: 100%;
        }
    }

    .about {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15)), linear-gradient(to top left, #49c3c9, #249bd6 50%, #0c5b9c);
        margin: 0px;
        padding: 160px 20px;

        .content {
            display: block;
        }

        .bio {
            width: 100%;
            float: none;
            padding: 0px;
        }

        .who {
            width: 100%;
            float: none;
            padding: 0;
        }
    }

    .bulk-sms-pricing {
        .Header {
            padding: 0 5px;
            padding-top: 140px;

            .Header-title {
                font-size: 26px;
                padding: 0px 0px 30px 0px;
                line-height: 1.2;

                .common-PageSubtitle {
                    font-size: 28px;
                    line-height: 1.2;
                    padding-top: 15px;
                    display: block;
                }
            }
        }
    }

    .globalNav .navSection.mobile .rootLink {
        padding: 0;
    }

    .clients {
        padding-top: 30px;

        .clientHeader {
            width: 300px;
        }
        .clientSpeak {
            width: 90%;

            div {
                width: 100%;
            }
        }
    }

    .clientSec {
        height: 110px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;

        &:before,
        &:after {
            background: -webkit-gradient(linear, left top, right top, from(#fff), to(hsla(0, 0%, 100%, 0)));
            background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
            content: "";
            height: 140px;
            position: absolute;
            width: 220px;
            z-index: 2;
        }

        &:before {
            left: 0;
            top: 0;
        }

        &:after {
            right: 0;
            top: 0;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .clientSecSlider {
            -webkit-animation: scroll 40s linear infinite;
            animation: scroll 40s linear infinite;
            display: inline-block;
        }

        .extraClientSecSlider {
            display: flex;
        }

        .logos-item {
            height: 90px;
            width: 200px;
            display: inline-block;
        }
    }

    .features-page {
        h1 {
            font-size: 22px;
        }
    }
}
@media (min-width: 320px) and (max-width: 480px){
    .top-header {
        height: 70px;
        .missed-call {
            display: none;
        }
    }
    .contact-us-wrapper {
        flex-wrap: wrap;

        .content-form,
        .content-wrapper {
            width: 100%;
        }
    }
    .globalNav{
        top:70px;
        .secHeader{
            display: none;
        }
        .navSection.mobile{
            padding: 5px 0px;
        }
        .navSection.logo {
            position: absolute;
            .rootLink{
                padding: 0px;
            }
        }
    }
    .trusted-brand {
        .container-brand {
            .brand-heading {
                .line {
                    .extraClientSecSlider {
                        display: inline-block;
                    }

                    .logos {
                        display: inline-block;
                        border: 0;
                    }
                }
            }
        }
    }
    .globalContent .container-lg{
        padding: 0;
    }
    .services-container{
        padding: 160px 0px 20px 0px;
        .service-types{
            margin-bottom: 20px;
            margin-top: 15px;
            .service{
                margin-bottom: 30px;
                width: 100%;
                border-radius: 2px;
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
                padding: 10px;
                .svg-icon{
                    width: 25%;
                }
            }
        }
    }
    .customer-group{
        .customer-sector{
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
            padding-bottom: 10px;
            .icon,.text{
                vertical-align: middle;
                padding: 0 5px;
                .svg-icon{
                    width: 100%;
                }
            }
        }
    }
    .promotiona-section{
        padding: 160px 0 30px 0;
        .feature-details-mobile{
            img{
                margin: 0 auto;
                width: 225px;
                display: block;
                margin-bottom: 10px;
            }
        }
        .feature-details{
            display: none;
            .col-2{
                width: 100%;
                margin: 20px 0;
                p{
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 10px;
                }
                img{
                    margin-bottom: 10px;
                    max-width: 100%;
                }
                .svg-icon{
                    vertical-align: top;
                }
                .text{
                    vertical-align: top;
                    margin-bottom: 10px;
                }
            }
        }
        .m-feature-details{
            display: block;
        }
        .promotional-features-table{
            width: 100%;
            td{
                padding: 10px;
            }
        }
    }
    .promotional-features-section .list{
        width: 100%;
        padding: 0px;
        margin-bottom: 10px;
        .box-list{
            padding: 20px 0px;
            p{
                padding: 0px 10px;
            }
            .main{
                .svg-icon{
                    width: 50px;
                }
                p{
                    padding: 0;
                }
            }
        }
    }
    section.contact-sales-header{
        padding-top: 50px;
        .headline{
            padding-top: 100px;
            .container-lg{
                padding: 0;
            }
            .headline-text{
                font-size: 30px;
                margin-bottom: 0;
            }
        }
        .free-demo-conatiner{
            .common-Button--default{
                margin-top: 15px;
            }
        }
    }
    .video-text,.video{
        display: none;
    }
    .footer-container{
        .col{
            width: 100%;
            padding: 20px 10px 0px 0px;
        }
        .col-right{
            float: unset;
            text-align: unset;
            padding: 20px 0px 20px 0px;
        }
        .col-center{
            text-align: unset;
            padding: 20px 0px 0px 0px;
        }
    }
    .sms-toolkit{
        .container-lg{
            margin-top: 20px;
            padding: 0;
        }
        .free-demo-conatiner{
            margin-top: 8px;
            text-align: center;
            border-radius: 0;
            .common-Button--default{
                margin-top: 15px;
                width: 220px;
                text-align: center;
            }
        }
        .feature-btn{
            margin: 0 auto;
            width: 240px;
            display: block;
        }
    }
    .toolkit-msg{
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 10px;
        text-align: center;
        width: 100%;
    }
    #illustrations{
        display: none;
    }
    main header{
        padding-bottom: 50px;
    }
    .customer-type{
        margin-top: -60px;
        padding-bottom: 30px;
        .card-documentation{
            flex: unset;
            display: inline-block;
            flex-basis: unset;
            width: 100%;
            margin: 5px;
            padding: 10px 5px;
            .svg-icon{
                position: static;
                width: 40px;
                margin: 0 auto;
            }
            h2{
                text-transform: unset;
                padding-left: 0px;
                text-align: center;
            }
            p{
                font-size: 12px;
                text-align: center;
            }
        }
    }
    .m-customer-type{
        display: block;
    }
    .promotional-features-section.services-provided{
        margin-top: 0;
        .list{
            width: 100%;
        }
    }
    #intro{
        .container-lg {
            padding: 150px 0px 40px 0px;
            h2,h3{
                margin-top: 30px;
                font-size: 16px;
                text-align: center;
            }
            h1{
                margin-top: 10px;
                text-align: center;
                font-size: 22px;
                font-weight: 600;
            }
        }
    }
    #complete-toolkit{
        padding: 0;
        .starService{
            width: 100%;
            .common-BodyText{
                padding-left: 0px;
            }
            .mainTitleGreen{
                font-size: 20px;
            }
        }
        .toolkit{
            width: 100%;
            margin: 30px auto;
            span{
                display: inline-block;
            }
        }
        .m-price-btn{
            display: block;
            width: auto;
            margin-top: 20px;
        }
    }
    .features-home{
        font-size: 24px;
    }
    .feature{
        width: 90%;
        margin-bottom: 15px;
        margin: 0 auto;
        display: block;
        p{
            width: 85%;
            margin-bottom: 10px;
        }
    }
    .detailed{
        height: auto;
        padding-bottom: 30px;
        .container-lg{
            padding: 0;
        }
    }
    .api-details{
        height: auto;
        padding-bottom: 30px;
        padding-top: 160px;
        h1{
            padding: 0px 10px;
            font-size: 20px;
        }
        h2{
            padding: 0 10px;
            font-size: 16px;
            margin-bottom: 15px;
        }
        p{
            font-size: 18px;
            width: 90%;
            line-height: 1.3;
        }
        ul{
            width: 90%;
            li{
                .text{
                    width: 88%;
                }
            }
        }
    }
    .col-35,.col-65{
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .col-65{
        margin-top: 20px;
    }
    .Plan-title{
        padding: 20px 10px;
    }
    .Plan-description{
        padding: 0px;
        max-width: 95%;
    }
    .Plan-tableContainer{
        padding: 0px 10px;
    }
    .Plan-list{
        margin: 20px auto;
        padding: 0px 20px;
    }
    .Plan-table-cell{
        padding: 10px;
        font-size: 13px;
    }
    .pricing-package{
        width: 90%;
        margin: 0 auto;
        p{
            margin-top: 35px;
            font-size: 22px;
        }
        .table-layout{
            width: 100%;
        }
    }
    .about{
        background: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15)), linear-gradient(to top left, #49c3c9, #249bd6 50%, #0c5b9c);
        margin:0px;
        padding: 160px 20px;
        .content{
            display: block;
        }
        .bio{
            width: 100%;
            float: none;
            padding: 0px;
        }
        .who{
            width: 100%;
            float: none;
            padding: 0;
        }
    }
    .bulk-sms-pricing{
        .Header{
            padding: 0 5px;
            padding-top: 160px;
            .Header-title{
                font-size: 26px;
                padding: 0px 0px 30px 0px;
                line-height: 1.2;
                .common-PageSubtitle{
                    font-size: 28px;
                    line-height: 1.2;
                    padding-top: 15px;
                    display: block;
                }
            }
        }
    }

    .globalNav .navSection.mobile .rootLink{
        padding: 0;
    }
    .clients{
        padding-top: 30px;
        .clientHeader{
            width: 300px;
        }
        .clientSpeak{
            width: 90%;
            div{
                width: 100%;
            }
        }
    }
    .clientSec {
        height: 110px;
        overflow: hidden;
        position: relative;
        white-space: nowrap;

        &:before,
        &:after {
            background: -webkit-gradient(linear, left top, right top, from(#fff), to(hsla(0, 0%, 100%, 0)));
            background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
            content: "";
            height: 140px;
            position: absolute;
            width: 50px;
            z-index: 2;
        }

        &:before {
            left: 0;
            top: 0;
        }

        &:after {
            right: 0;
            top: 0;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
        }

        .clientSecSlider {
            -webkit-animation: scroll 40s linear infinite;
            animation: scroll 40s linear infinite;
            display: inline-block;
        }

        .extraClientSecSlider {
            display: flex;
        }

        .logos-item {
            height: 90px;
            width: 200px;
            display: inline-block;
        }
    }
    .features-page{
        h1{
            font-size: 22px;
        }
    }
}
.requestDemo-msg {
    color: white;
    padding-top: 20px;
    font-size: 16px;
    padding-left: 50px;
    font-style: italic;
}
@keyframes scroll{
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}