$font-family-serif: Garamond, "Times New Roman", serif;
$font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;

$grey-border: #eee;
$grey-text: #aaa;
$black: #000;
$white: #fff;

#appBody {
  color: $black;
//   font-family: 'Roboto', sans-serif;
//   background: #eee;
  height:100%;
  background: #fff;
min-height: 100%;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
font-size: 62.5%;
font-family: Camphor,Open Sans,Segoe UI,sans-serif;
font-weight: 400;
font-style: normal;
-webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-rendering: optimizeLegibility;
font-feature-settings: "pnum";
font-variant-numeric: proportional-nums;
}
#root{
    height: 100%;
}
.login{
    height: 100vh;
}

.App {
  font-size: 1.8rem;
  height: 100%;
}

h1,
h2,
h3 {
  margin: 2rem 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.7rem;
}

hr {
  border: 0;
  border-top: 1px solid $grey-border;
  margin: 2rem 0;
}

.home {
    margin: 0 auto;
    width: 75%;
    margin-top: 60px;
}
.m-login{
    margin: 0 auto;
    margin-top: 80px;
    position:relative;
    width: 90%;
}
.d-login{
    margin: 0 auto;
    width:50%;
    position:relative;
}
.d-sms-btn-option-btn{
    margin-left:10px;
}
.m-sms-btn-option-btn{
    margin-left:10px;
    margin-bottom: 10px;
}
.width90pc{
    width: 90% !important;
}
.width75pc{
    width: 75% !important;
}
.uploadExcelPreview{
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 16px;
    font-size: 14px;
}
@media (max-width: 600px) {
    .uploadExcelPreview{
        padding: 10px;
    }
    .global--modal-content {
        width: 100% !important;
        transform: translate(0, 0) !important;
        position: absolute !important;
        top: 0px !important;
        height: 100% !important;
        min-height: 100% !important;
    }
    .global--modal-body {
        padding: 0px 10px !important;
        max-height: none !important;
    }
    .global--modal-paper {
        overflow: auto !important;
        height: 100% !important;
        min-height: 100% !important;
    }
    .global--modal-action{
        position: fixed !important;
        bottom: 0px !important;
        padding: 15px 10px !important;
        background: white !important;
        border-top: 1px solid #e0e0e0 !important;
        z-index: 1;
    }
    .iphone-touch-scroll{
        -webkit-overflow-scrolling: touch;
    }
}
.icon-Logo{
    position: absolute;
    width: 100px;
    height: 100px;
    top: -50px;
    margin: 0 auto;
    left: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 50% !important;
    .icon-img{
        width: 60px;
        height: 60px;
        margin: 0 auto;
        display: block;
        margin-top: 10px;
    }
}
.login-panel {
    display: block;
    margin: 0 auto;
    padding: 20px;
    padding-top: 30px;
}

.login-btn{
    float: right;
    margin-top: 20px;
}

.login-error{
    color: rgb(244, 67, 54);
    font-size: 14px;
    margin: 0px;
    margin-top: 10px;
}
.m-sms,.d-sms{
    margin: 0 auto;
    .tot-con-sel{
        font-size: 12px;
    }
    .sms-text{
        font-size:18px;
    }
    .sms-div{
        overflow:hidden;
        border:1px solid #e4e4e4;
        border-radius:4px;
        padding:10px 20px 10px 20px;
        margin-top:10px;
        width:100%;
        display:inline-block;
        vertical-align:top;
        background: white;
    }
    .drop-down-sign{
        width:200px;
        font-size:12px !important;
        height:42px !important;
        vertical-align:middle;
    }
}
.m-sms{
    width: 100%;
    .tot-con-sel{
        margin: 0px;
    }
    .sms-text{
        margin-top:5px;
    }
    .sms-div{
        padding: 10px;
    }
    .drop-down-sign{
        width: 140px;
    }
}
.d-sms{
    width: 90%;
    margin-top: 20px;
    margin-bottom:50px;
    color:white !important;
    background: rgba(0, 0, 0, 0.7) !important;
    .tot-con-sel{
        margin: 10px 0 0 10px;
    }
    .sms-text{
        margin-top:10px;
        margin-bottom:10px;
    }
}
.d-contact-details,.m-contact-details{
    background:#f7f7f7;
    padding: 0 20px !important;
    border-bottom: 1px solid #e0e0e0;
    overflow:hidden;
    .name{
        padding:20px 0;
        display:inline-block;
        margin-left:20px;
    }
    .actions{
        float:right;
        display:inline-block;
        padding-top:15px;
    }
}
.m-contact-details{
    padding: 0 10px !important;
    padding-right: 0px !important;
    .name{
        padding:10px 0;
        display:inline-block;
        margin-left:10px;
        font-size: 15px;
    }
    .actions{
        padding-top: 4px;
    }
}
.m-contact-list,.d-contact-list{
    .add-btn{
        border-radius:50% !important;
        min-width:50px !important;
        position:fixed;
        bottom:25px;
        right:25px;
        z-index:100;
    }
}
.d-edit-contact,.m-edit-contact{
    .edit{
        margin-left:20px;
        width:90% !important;
    }
    .select{
        margin-left:20px;
        vertical-align: middle;
    }
}
.m-edit-contact{
    .edit{
        width: 75% !important;
    }
}
.m-create-group,.d-create-group{
    .group-name{
        background:white;
        width:70% !important;
    }
}
.m-create-group{
    .group-name{
        width: 55% !important;
    }
}
.d-group-details,.m-group-details{
    background:white;
    padding:20px 0px;
    .g-name{
        margin:0 0 5px 10px;
        font-size:14px;
        height:20px;
    }
}
.m-group-details{
    padding:10px 0px;
    .btn-cnt{
        overflow: hidden;
    }
    .g-name{
        display: inline-block;
        margin-top: 10px;
    }
}
.padT0{
    padding-top: 0px !important;
}
.m-recipient-list,.d-recipient-list{
    .search{
        width:95%;
        position:fixed;
        background:white;
        z-index:1;
    }
    .search-box{
        width:80% !important;
        vertical-align:middle;
        margin-left:5px;
    }
    .list{
        padding-top: 90px !important;
    }
}
.m-recipient-list{
    .search{
        margin-top: -25px;
    }
    .search-box{
        width:65% !important;
    }
    .list{
        padding-top: 65px !important;
    }
}
.row-linebreak{
    white-space:normal !important;
    word-wrap:break-word;
    padding: 10px !important;
    line-height:1.3;
}
@-moz-keyframes infinite-spinning {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes infinite-spinning {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#usageSMS {
   animation: infinite-spinning 3s infinite;
}
@-moz-keyframes infinite-flip {
  from {
    -moz-transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(180deg);
  }
}
@-webkit-keyframes infinite-flip {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(180deg);
  }
}
@keyframes infinite-flip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(180deg);
  }
}
#leftSMS {
   animation: infinite-flip 2s 3s infinite;
}
@-moz-keyframes infinite-swing {
  from {
    -moz-transform: rotateY(0deg);
  }
  to {
    -moz-transform: rotateY(80deg);
  }
}
@-webkit-keyframes infinite-swing {
  from {
    -webkit-transform: rotateY(0deg);
  }
  to {
    -webkit-transform: rotateY(80deg);
  }
}
@keyframes infinite-swing {
    0% {
        transform: rotate(0deg);
    }
    12% {
        transform: rotate(15deg);
    }
    25% {
        transform: rotate(30deg);
    }
    37% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(0deg);
    }
    62% {
        transform: rotate(-15deg);
    }
    75% {
        transform: rotate(-30deg);
    }
    87% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
#notification{
    animation: infinite-swing 1s infinite;
}
.dashboard-tile{
    height: 131px;
    width: 28%;
    margin: 2%;
    padding: 10px 30px;
    display: inline-block;
    vertical-align:top;
}
.CTA{
    border:1px solid #1975d2;
    color:#1975d2;
    padding:5px 10px;
    margin:0 auto;
    font-size: 18px;
    width:130px;
    text-align: center;
    cursor: pointer;
    margin-top:20px;
    border-radius: 4px;
}
@media (max-width: 600px) {
    .dashboard-tile{
        width: 100%;
        margin: 2% 0;
    }
    .login-panel{
        padding:10px;
    }
}
.historyRow{
    .rowCommon{
        color:black;
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
    }
    .status{
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        padding-right: 0;
    }
}
.historyRow.header{
    .rowCommon{
        color:#747474;
    }
}
.historyItem{
    background: white !important;
}
@media (min-width: 600px) {
    .historyItem{
        &:hover{
            transform: translateY(-1px);
            box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
        }
    }
}
.smsView{
    height: 100%;
    position: fixed;
    background-image: url(//d1eisuc5webqir.cloudfront.net/client-ui/Images/BGImage.jpeg);
    background-size: cover;
}
.footer{
    height: 50px;
    padding-top: 15px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    div{
        text-align: center;
        color: white;
        font-size: 14px;
    }
}
.grpname{
    &:hover{
        text-decoration: underline;
    }
}
.templateAddBtn{
    border-radius:50% !important;
    min-width:40px !important;
    float: right;
}
.admindata{
    p{
        display: inline-block;
        margin: 0 0 0 10px;
        vertical-align: top;
    }
}

// New Dashboard
.dashboard-container {
    background: #eeeeee;
    padding: 24px;
    min-height: calc(100vh - 64px);
}
.dashboard-heading {
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 600;
    color: #212121;//grey 900
}
.dashboard-sub-heading {
    font-size: 12px;
    color: #424242;//grey 700
}

.dashboard-card-container {
    margin-top: 16px;
    height: 100px;
    .last-activity-msg {
        font-size: 18px;
    }
}

.dashboard-error-msg {
    text-align: center;
    color: #e53935;
    svg {
        font-size: 50px;
        
    }
    p {
        font-size: 14px;
        // color: #e53935;
    }
}
.dashboard-sms-usage {
    display: flex;
    align-items: center;
    .dashboard-sms-usage-selection {
        gap: 8px;
    }
    .dashboard-sms-usage-duration {
        margin-left: auto;
    }
}
.dashboard-offers {
    list-style: disc;
    padding: 0px 20px;
    font-size: 16px;
    line-height: 1.7;
    margin-top: 16px;
}
.dashboard-contact-us {
    margin-top: 16px;
    font-size: 16px;
    p {
        display: flex;
        align-items: center;
        span {
            margin-left: 4px;
        }
        &:first-of-type{
            margin-bottom: 6px;
        }
    }
}

.overflow-auto {
    overflow: auto;
}
//SMS
.sms-parent-container {
    background: #f9f9f9;
    padding: 24px;
    min-height: calc(100vh - 64px);
}
.sms-container {
    border-radius: 8px;
    background-color: white;
    border: 1px solid #bdbdbd;
    padding: 16px;
}
.sms-small-container {
    margin-top: 4px;
    margin-bottom: 16px;
    background: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 22px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.sms-small-container-new {
    margin-top: 4px;
    margin-bottom: 16px;
    background: white;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid rgba(25, 118, 210, 0.5);
    box-shadow: rgba(25, 118, 210, 0.04) 0px 5px 22px 0px,
        rgba(25, 118, 210, 0.06) 0px 0px 0px 1px;
}

.react-file-reader {
    margin-top: -4px;
}
// Disable the license message
.MuiDateRangeCalendar-root > div:first-child {
    display: none;
} 