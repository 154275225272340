$white: #f9f9f9;
.activeBlue{
    background: #1976d2;
}
.activeGreen{
    background: #2fb97f;
}
.activePurple{
    background: #006a94;
}
.activeLightBlue{
    background: #105fa0;
}
.ed-white{
	background-color: white;
    border-bottom: 1px solid #ececec;
}